import axios from "axios";
import { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function App() {
  const book = useRef();

  const [dataMenu, setDataMenu] = useState([]);
  const [menuPage, setMenuPage] = useState(-1);
  const [uaChonPage, setUaChonPage] = useState(-1);
  const [dacBietPage, setDatBietPage] = useState(-1);
  const [drinkPage, setDrinkPage] = useState(-1);

  useEffect(() => {
    getMenu();
  }, []);

  const getMenu = () => {
    axios
      .get(`${process.env.REACT_APP_BASE}/menu/mobile`)
      .then((res) => {
        res.data.detail.forEach((item) => {
          if (item.typePage === "Home") setMenuPage(item.page);
          if (item.typePage === "First") setUaChonPage(item.page);
          if (item.typePage === "Second") setDatBietPage(item.page);
          if (item.typePage === "Drink") setDrinkPage(item.page);
        });
        setDataMenu(res.data.detail);
      })
      .catch((err) => toast.error("Lỗi khi get menu"));
  };

  return (
    <div
      style={{
        background: "#000",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ToastContainer />
      <HTMLFlipBook
        mobileScrollSupport={true}
        style={{ overflow: "hidden" }}
        flippingTime={750}
        drawShadow={false}
        ref={book}
        height={1176}
        width={960}
        size="stretch"
      >
        {dataMenu &&
          dataMenu?.map((item, index) => (
            <img
              alt="menu"
              src={`${process.env.REACT_APP_IMAGE}${item.image}`}
              key={index}
              loading="lazy"
            />
          ))}
      </HTMLFlipBook>

      {menuPage !== -1 && (
        <button
          onClick={() => book.current.pageFlip().turnToPage(menuPage)}
          style={{
            background: "#A7202C",
            border: "1px #fff solid",
            position: "fixed",
            top: "50px",
            right: "0px",
            borderRadius: "50% 5px 50% 50%",
            width: "50px",
            height: "50px",
          }}
        >
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/home.svg`}
            style={{ width: "20px" }}
          />
        </button>
      )}
      {uaChonPage !== -1 && (
        <button
          onClick={() => book.current.pageFlip().turnToPage(uaChonPage)}
          style={{
            background: "#A7202C",
            border: "1px #fff solid",
            position: "fixed",
            top: "110px",
            right: "0px",
            borderRadius: "50% 5px 50% 50%",
            width: "50px",
            height: "50px",
          }}
        >
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/meal.svg`}
            style={{ width: "20px" }}
          />
        </button>
      )}
      {dacBietPage && (
        <button
          onClick={() => book.current.pageFlip().turnToPage(dacBietPage)}
          style={{
            background: "#A7202C",
            border: "1px #fff solid",
            position: "fixed",
            top: "170px",
            right: "0px",
            borderRadius: "50% 5px 50% 50%",
            width: "50px",
            height: "50px",
          }}
        >
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/meat.svg`}
            style={{ width: "20px" }}
          />
        </button>
      )}
      {drinkPage && (
        <button
          onClick={() => book.current.pageFlip().turnToPage(drinkPage)}
          style={{
            background: "#A7202C",
            border: "1px #fff solid",
            position: "fixed",
            top: "230px",
            right: "0px",
            borderRadius: "50% 5px 50% 50%",
            width: "50px",
            height: "50px",
          }}
        >
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/drink.svg`}
            style={{ width: "20px" }}
          />
        </button>
      )}
    </div>
  );
}

export default App;
